import React from "react"

import Newsletter from "../components/form/form"

const NewsletterForm = () => (
  <div id="newsletter">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-12 mt-4">
          <h2>SIGN UP FOR OUR NEWSLETTER</h2>
        </div>
        <div className="col-md-12">
          <Newsletter
            form_name="Newsletter"
            form_id="f09a7ea5-cda8-41ae-bff3-8d446d7c65cb"
            form_classname="newsletter"
            data-thankyou-url="/thank-you"
          >
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="full_name">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="Name"
                    id="full_name"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email_add">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    id="email_add"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="webriq-recaptcha" id="rc-imageselect" />
                <button
                  className="btn btn-primary hvr-shadow mt-3"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Newsletter>
        </div>
      </div>
    </div>
  </div>
)

export default NewsletterForm
