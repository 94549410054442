import React from "react"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import NewsletterForm from "../components/newsletter-form"
import ContactInfo from "../components/contact-information"

const Newsletter = () => (
	<Layout>
		<SEO
			title="Corporate Coaching Companies - Leadership Coaching Courses"
			description="AGL is proud to be a corporate coaching company. Through leadership coaching courses you will see your employees grow closer together & truly be a team. Learn how today!"
		/>
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h1>
						<strong>NEWSLETTER</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content contact">
			<div className="container">
				{/*<div className="text-center">
          <h2 className="mb-0 ">
            <strong>SIGN UP FOR OUR NEWSLETTER </strong>{" "}
          </h2>
        </div>*/}
				<div className="row pt-5">
					<div className="col-md-6">
						<ContactInfo />
					</div>
					<div className="col-md-6">
						<NewsletterForm />
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Newsletter
